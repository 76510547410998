.download-container {
  margin-top: 40px;
  justify-content: flex-start;
  article {
    order: 0;
    flex: 0 1 calc(33.3% - 20px);
    align-self: auto;
    margin: 18px 20px 30px 0;

    figure {
      position: relative;
      margin: 0;
      overflow: hidden;
      padding: 0 4px 4px 0;
      min-width: 77px;
      flex: 0 0 auto;
      align-self: flex-start;
      img {
        display: block;
        position: relative;
        z-index: 2;
        max-width: 73px;
        border: 1px solid $blue;
      }
      &:after {
        @include pseudo();
        width: 100%;
        height: 100%;
        background: $blue;
        left: 4px;
        top: 4px;
        z-index: 1;
      }
    }

    a {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-content: stretch;
      text-decoration: none;
      .download-text {
        margin-left: 16px;
      }
      @include font(16px, 700, $blue, 1.25);
      span {
        &.title {
          margin-bottom: 10px;
          display: block;
        }
      }
      .download {
        .icon {
          display: inline-block;
          margin-right: 9px;
          width: 22px;
          height: 22px;
          vertical-align: middle;
          border: 2px solid $blue;
          border-radius: 50%;
          background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAARVBMVEUAAAAQYI8NXY0NXo4NXo8AVY4OX40NYIwNXo0NXo4OX48NXo4NXo4OXY8PXI8NXo4OXZANXo4NXY0NXY4OYJANXo4AAABvT8UlAAAAFXRSTlMAIMqFmAleKJ38W7b9XTLwN19g3TVhSiO8AAAAAWJLR0QAiAUdSAAAAAlwSFlzAAAN1wAADdcBQiibeAAAAAd0SU1FB+ILAg86H+l8fQIAAABySURBVCjP5c3bGoAQEIXRidJBJ2q//6uGSSJv0L7x+dfFED1rhGioNgnIKrRA+y/oVJ9Dr7rwDhinN0wjhgAamJcEywzoAOvmREVQrm8rH9m9GAbj+x7PB7EebNZvOYCj6Cy8vCcpe5RvZ6l1olOIM/0u5/YKhaLFwQYAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTgtMTEtMDJUMTU6NTg6MzErMDE6MDCE+zJTAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE4LTExLTAyVDE1OjU4OjMxKzAxOjAw9aaK7wAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII=') no-repeat center -1px;
          background-size: 12px auto;
          transition: background-position .25s ease-in-out;
        }
        span {
          vertical-align: middle;
        }
      }
      &:hover .download .icon {
        background-position: center 2px;
      }
    }
  }
}
