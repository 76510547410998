.expert-container {
  .box-title {
    display: block;
    @include font(16px, 700, $blue, 1.25);
    margin-bottom: 6px;
  }
  figure {
    margin: 0;
    display: block;
    position: relative;
    overflow: hidden;
    img {
      display: block;
      visibility: hidden;
    }
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
      background: no-repeat center center;
      background-size: cover;
      transition: opacity .3s ease, transform .45s ease;
    }
  }
  p {
    margin: 20px 0;
    @include font(16px, 400, $blue, 1.1875);
  }
  .box-half {
    cursor: pointer;
  }
  .box-half:hover {
    figure .bg {
      opacity: .87;
      transform: scale(1.03);
    }
  }
}

.video-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
}

.vid-container {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
}

.plyr {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
