.contact-container {
  margin-top: 50px;
  .info {
    @include font(16px, 300, #7e7e7e, 1.25);
    margin-bottom: 20px;
  }
  form {
    max-width: 532px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
    align-items: flex-start;
    > div {
      margin-bottom: 20px;
      position: relative;
      &.half {
        width: calc(50% - 10px);
      }
      &.full {
        width: 100%;
      }
    }

    input, select, textarea {
      width: 100%;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: 1px solid #707070;
      background: #fff;
      border-radius: 0;
      padding: 5px 10px 4px;
      line-height: 30px;
      &:focus {
        outline: none;
      }
    }
    .select-container:after {
      @include pseudo();
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: #5c5c5c transparent transparent transparent;
      top: 17px;
      right: 11px;
      z-index: 1;
      pointer-events: none;
    }
    textarea {
      resize: none;
      height: 330px;
    }
    option[disabled] {
      display: none;
    }
    input[type="submit"] {
      max-width: 254px;
      border-radius: 8px;
      border-color: $blue;
      cursor: pointer;
    }
  }
  p {
    margin: 0;
  }
}

.wpcf7-response-output {
  border: none !important;
  margin: 20px 0 !important;
  padding: 0 !important;
  @include font(16px, 400, red, 1.25);
}

.wpcf7-mail-sent-ok {
  color: #318F14 !important;
}


.datenschutz-container {
  margin: 10px 0 30px !important;
  .styled-checkbox {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it

    & + .wpcf7-list-item-label > div {
      position: relative;
      cursor: pointer;
      padding: 0;
      @include font(16px, 300, $blue, 1.25);
      a {
        @include font(16px, 300, $blue, 1.25);
      }
      span {
        float: left;
        width: calc(100% - 35px);
      }

    }

    // Box.
    & + .wpcf7-list-item-label > div:before {
      content: '';
      margin-right: 15px;
      float: left;
      vertical-align: text-top;
      width: 20px;
      height: 20px;
      background: white;
      border: 2px solid $blue;
      position: relative;
      border-radius: 3px;
    }

    // Box hover
    &:hover + .wpcf7-list-item-label > div:before {
      background: $blue;
    }

    // Box focus
    &:focus + .wpcf7-list-item-label > div:before {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    // Box checked
    &:checked + .wpcf7-list-item-label > div:before {
      background: $blue;
    }

    // Disabled state .wpcf7-list-item-label > div.
    &:disabled + .wpcf7-list-item-label > div {
      color: #b8b8b8;
      cursor: auto;
    }

    // Disabled box.
    &:disabled + .wpcf7-list-item-label > div:before {
      box-shadow: none;
      background: #ddd;
    }

    // Checkmark. Could be replaced with an image
    &:checked + .wpcf7-list-item-label > div:after {
      content: '';
      position: absolute;
      left: 5px;
      top: 9px;
      background: white;
      width: 2px;
      height: 2px;
      box-shadow:
        2px 0 0 white,
        4px 0 0 white,
        4px -2px 0 white,
        4px -4px 0 white,
        4px -6px 0 white,
        4px -8px 0 white;
      transform: rotate(45deg);
    }
  }
}
span.wpcf7-list-item {
  margin-left: 0 !important;
}

span.wpcf7-not-valid-tip {
  display: none;
}

.wpcf7-not-valid {
  border-color: red !important;
}


.kontakt-content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: space-between;
  align-items: flex-start;
  .text {
    max-width: 440px;
    margin-top: 70px;
    @include font(16px, 300, $blue, 1.25);
  }

}
