.teaser {
  background: $lblueBG;
  width: 100%;
  padding: 44px 30px;

  &.full-teaser {
    margin: 60px 0 30px;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
  }

  p {
    @include font(16px, 300, $blue, 1.25);
    margin: 0 0 32px;
  }
}


.box-half {
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  max-width: calc(50% - 10px);
  margin: 30px 0 30px;
  figure {
    margin: 0;
    position: relative;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
    figcaption {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      @include font(16px, 300, $blue, 1.25);
      padding: 20px 50px 30px 20px;
      background: rgba(234, 247, 255, .9);
    }
  }

  h2 {
    font-weight: 400;
    margin: 0 0 12px;
  }

  .teaser {
    padding: 30px 42px 20px 16px;
    min-height: 200px;
    box-sizing: border-box;
    position: relative;
    .button {
      position: absolute;
      bottom: 20px;
      left: 16px;
    }
  }
}

.box-full {
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  width: 100%;
  margin: 30px 0 30px;
}
