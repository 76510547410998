.main-footer {
  padding: 60px 0 50px;
  background: $lblueBG;
}

.footer-content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: flex-start;
}

.footer-logo {
  @media all and (min-width: 921px) {
    margin-right: 10vw;
  }
  @media all and (max-width: 800px) {
    display: none;
  }
}

.footer-nav {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      @include font(16px, 300, false, 2);
      a {
        color: $blue;
        &:hover {
          font-weight: 400;
        }
      }
    }
  }
}

.footer-text {
  p {
    margin-top: 0;
    @include font(16px, 300, $blue, 1.5625);
    strong {
      font-weight: 700;
    }
    &:first-child {
      margin-bottom: 35px;
    }
    a {
      @include font(16px, 300, $blue, 1.5625);
      &:hover {
        font-weight: 400;
      }
    }
  }
}
