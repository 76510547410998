html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

.sticky {
  z-index: 1000;
}
