.custom-anim, .c-anim {
  opacity: 0;
}

.alpha-anim {
  transition: opacity 1.4s ease-out;

  &.start-animation {
    opacity: 1;
  }
}

.anim-lr {
  transform: translate(-10px, 0);
  transition: transform .4s ease-out,opacity .8s ease-out;
  backface-visibility: hidden;
  &.start-animation {
    opacity: 1;
    transform: translate(0,0)
  }
}

.anim-ttb {
  transform: translate(0, -10px);
  transition: transform .4s ease-out,opacity .8s ease-out;
  backface-visibility: hidden;
  &.start-animation {
    opacity: 1;
    transform: translate(0,0)
  }
}

.anim-btt {
  transform: translate(0, 20px);
  transition: transform .4s ease-out,opacity .8s ease-out;
  backface-visibility: hidden;
  &.start-animation {
    opacity: 1;
    transform: translate(0,0)
  }
}

.anim-rl {
  transform: translate(10px, 0);
  transition: transform .4s ease-out,opacity .8s ease-out;
  backface-visibility: hidden;
  &.start-animation {
    opacity: 1;
    transform: translate(0,0)
  }
}