// cookie styles
.cookie-note--wrap {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  @media all and (max-width: 920px) {
    padding-bottom: 20px;
  }
  z-index: 10;
  opacity: 0;
  transform: translate3d(0, 100%, 0);
  transition: opacity .4s ease, transform .6s ease-in-out;
  &.is-visible {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: $blue;
    opacity: .85;
    z-index: 11;
  }

  .container {
    position: relative;
    z-index: 12;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    @media all and (max-width: 920px) {
      flex-wrap: wrap;
    }
  }

  color: #fff;

  .text-content {
    width: 100%;
    max-width: 600px;
    @media all and (min-width: 1130px) {
      max-width: 840px;
    }

    @media all and (max-width: 920px) {
      max-width: 100%;
    }


    order: 1;
    flex: 0 1 auto;
    align-self: auto;
  }

  .title {
    display: block;
    font-size: 18px;
  }

  p {
    font-size: 14px;
    color: #fff;
  }

  a {
    color: #fff !important;
    text-decoration: underline;
    &:hover {
      color: inherit;
    }
  }


  .button-ok {
    order: 1;
    flex: 1 1 auto;
    align-self: auto;
    text-align: center;
    @media all and (max-width: 920px) {
      flex: 0 1 auto;
      text-align: left;
    }
  }

  .button-ok {
    text-align: right;
    a {
      text-decoration: none;
      background: transparent;
      border: 1px solid #fff;
    }

    @media all and (max-width: 920px) {
      text-align: left;
      margin-right: 30px;
    }
  }

}

