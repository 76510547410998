.overlay {
  position: fixed;
  z-index: 2000;
  background: rgba(255,255,255,.75);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: translate3d(0, 5px, 0);
  visibility: hidden;
  transition: opacity .55s ease, transform .55s ease, visibility 0s ease .55s;

  &.is-visible {
    transform: translate3d(0,0,0);
    visibility: visible;
    opacity: 1;
    transition: opacity .55s ease, transform .55s ease;
  }
}

.close-overlay {
  position: absolute;
  top: 0;
  right: -82px;
  width: 42px;
  height: 42px;
  z-index: 2000;
  cursor: pointer;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABABAMAAABYR2ztAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAGFBMVEUAAAARZogMXo4OXZANXo4NXo4NXo4AAACrnpl/AAAABnRSTlMAD7k3yPATNs0fAAAAAWJLR0QAiAUdSAAAAAlwSFlzAAAN1wAADdcBQiibeAAAAAd0SU1FB+ILAg8LNfSas2YAAAD1SURBVEjHhZbNDcIwDEaNugBCLMCFM5cOwIUFkBgAlew/Am1TSn78+fWQg5PvJbEdu2anm8lvuJgdHk+9YJyOdk4fiRhe6W7XlCRiTOm9DAoxA2ZxHhVg0UrETyoRu1Ig/kKBKHQuopS5iErlIGqRg2g0HaKVdIhO0Rj6PRuLc+rK5N27srmeK4y+7wuriN5uVvHf7TKDtgmdg9tMkMXrVJDEGREAMiIAZEQEWBERICMiAC+gLfCQdE10FLkag0XhxoShlMOkpbTHh0NPDx8vPX8sIFSCsIhRGcRCSqUYizm1A2wo1JKwqWFbxMaKrRmbO/0efAGgRrHXiyfDuQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOC0xMS0wMlQxNToxMTo1MyswMTowMJnHcrUAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTgtMTEtMDJUMTU6MTE6NTMrMDE6MDDomsoJAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==') no-repeat center center;
  background-size: 100%;
}
