html {
  font-family: "Libre Franklin", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  @include font(24px, 600, $blue, 1.16);
  margin-top: 0;
}

small {
  @include font(14px, 300, $blue, 1.21);
}

h1.title {
  font-weight: 700;
  line-height: 1.21;
}

h2.subtitle {
  @include font(24px, 300, $blue, 1.15);
  margin: 40px 0 18px;
}

.text-content {
  h2 {font-size: 22px; }
  h3 { font-size: 20px; }
  h4 { font-size: 18px; }
  h5 { font-size: 16px; }
  h6 { font-size: 14px; }

  p, ul, li {
    @include font(16px, 300, $blue, 1.25);
  }
  strong {
    font-weight: 700 !important;
  }
  p a {
    color: $blue;
  }


}
