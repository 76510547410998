// header styles
.main-header {
  width: 100%;
  &:not(.home-header) {
    max-height: 160px;
  }


  .oh {
    margin-bottom: 25px;
  }

  img {
    float: left;
    display: block;
  }

  .meta {
    float: right;
    margin-top: 18px;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        display: inline-block;
        vertical-align: middle;
        @include font(16px, 300, #000, 1.18);
        margin-left: 50px;
        a {
          color: #000;
          text-decoration: none;
          transition: color .25s ease;
          &:before {
            @include pseudo(inline-block, relative);
            width: 18px;
            height: 18px;
            vertical-align: middle;
            top: -2px;
            background: $lblue;
            margin-right: 15px;
            transition: background .25s ease;
          }
        }
        &.current-menu-item a,
        a:hover {
          color: $blue;
          &:before {
            background: $blue;
          }
        }
      }
    }
  }
}

/* Navigation */
.main-nav {
  padding-top: 0;
  width: 100%;
  clear: both;
  position: relative;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(168,168,168,0.16);
  -moz-box-shadow: 0px 2px 4px 0px rgba(168,168,168,0.16);
  box-shadow: 0px 2px 4px 0px rgba(168,168,168,0.16);
  background: #fff;
  z-index: 1001 !important;
  ul:not(.submenu) {
    margin: 0;
    padding: 0;
    z-index: 1;
    background: #fff;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    > li {
      display: block;
      margin: 0 10px;
      vertical-align: top;
      order: 0;
      flex: 0 1 auto;
      align-self: auto;
      background: #fff;
      transition: background .3s ease;
      &:first-child {
        margin-left: 0;
      }
      > a {
        display: block;
        @include font(16px, 300, $blue, 1.25);
        height: 100%;
        box-sizing: border-box;
        max-width: 164px;
        padding: 10px;
        text-decoration: none;
        vertical-align: top;
      }

      &.current-menu-item {
        background: $lblueBG !important;
        > a {
          font-weight: 700;
        }
        .sub {
          opacity: 1;
          max-height: 70px;
        }
      }

      &:hover {
        background: $lblueBG;
        .sub {
          opacity: 1;
          max-height: 70px;
          z-index: 4;
        }
        .alt-sub {
          opacity: 1;
          max-height: 160px;
          z-index: 3;
        }
      }
    }
  }

  .sub {
    padding: 0;
    position: absolute;
    z-index: 2;
    top: auto;
    left: 0;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height .35s ease, opacity .35s ease;
    background: $lblueBG;
  }

  .submenu {
    list-style: none;
    padding: 20px 0 20px 20px;
    transition: transform .35s ease, opacity .35s ease;
    li {
      display: inline-block;
      &:after {
        @include pseudo(inline-block, relative);
        width: 40px;
        height: 2px;
        background: $blue;
        margin: 0 20px;
        vertical-align: middle;
      }
      &:last-child:after {
        display: none;
      }
      a {
        @include font(16px, 300, $blue, 1.25);
        text-decoration: none;
        &:hover {
          font-weight: 700;
        }
      }
      &.is-active a {
        font-weight: 700;
      }
    }
  }

  .alt-sub {
    position: absolute;
    padding: 0;
    top: auto;
    left: auto;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height .35s ease, opacity .35s ease;
    @media all and (max-width: 1180px) {
      right: 18px;
    }
  }

  ul.alt-submenu {
    padding: 23px 20px;
    background: $lblueBG;
    min-width: 250px;
    flex-direction: column !important;
    li {
      display: block;
      margin: 0 0 17px;
      white-space: nowrap;
      background: $lblueBG;
      a {
        padding: 0;
        max-width: 100%;
        &:hover {
          font-weight: 700;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.home-jumbotron {
  width: 100%;
  overflow: hidden;
  margin: 25px 0 57px;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
