@mixin pseudo($display: block, $pos: absolute, $content: ''){
  content: $content;
  display: $display;
  position: $pos;
}

@mixin font($size: false, $weight: 400, $colour: $blue, $lh: false) {
  font-family: $defaultFont;
  @if $weight { font-weight: $weight; }
  @if $size { font-size: $size; }
  @if $colour { color: $colour; }
  @if $lh { line-height: $lh; }
}