.container {
  width: 100%;
  max-width: 1080px;
  padding: 0 20px;
  margin: 0 auto;
}

.main-content {
  min-height: 500px;
  margin: 60px 0;
  overflow: hidden;
}

.home-container, .column-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  align-items: flex-start;
}

.image-content {
  margin-top: 0;
  margin-bottom: 40px;
  text-align: center;
  position: relative;
  min-height: 0;
  transition: min-height .25s ease;
  img {
    display: inline-block;
    max-width: 100%;
    height: auto;
  }

  .cat {
   /* position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    backface-visibility: hidden;
    transition: opacity .55s ease, transform .55s ease;
    transform: translateY(5px);
    &.is-visible {
      opacity: 1;
      transform: translateY(0);
    }*/
  }
}
