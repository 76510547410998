.fachinfo-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: stretch;
  max-width: 1130px;
}

.fachinfo-sidebar {
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  width: 100%;
  max-width: 348px;
  min-width: 348px;
}

.fachinfo-content {
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  margin-left: 20px;
  padding-top: 47px;
  max-width: 720px;
  img {
    display: block;
    width: 100%;
  }
}

.fachinfo-sidebar-content {
  padding-bottom: 30px;
}

.fachinfo-nav {
  border-left: 1px solid #0a86aa;
  border-right: 1px solid #0a86aa;

  ol {
    counter-reset: items;
    @include font(16px, 300, $blue, 1.2);
    padding: 0;
    li {
      position: relative;
      display: block;
      counter-increment: items;
      padding: 16px 0 13px 20px;
      background: #fff;
      transition: background .3s ease;
      span {
        position: relative;
        z-index: 1;
        padding-left: 60px;
        display: block;
      }
      a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
      }
      &:before {
        position: absolute;
        top: auto;
        left: auto;
        z-index: 1;
        content: '0' counter(items);
      }
      &:nth-child(n+10):before {
        content: counter(items);
      }
      &:hover, &.is-active {
        background: $lblueBG
      }
    }
  }
}
